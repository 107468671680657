import { defineAsyncComponent } from 'vue'

const modalWrap = (source, options) => ({
  source: defineAsyncComponent(source),
  options
})

export default {
  AppAlertModal: modalWrap(() => import('./alert')),
  AppConfirmModal: modalWrap(() => import('./confirm')),
  AppOPCServerModal: modalWrap(() => import('./opc-server-editor')),
  AppDashboardEditorModal: modalWrap(() => import('./dashboard-editor'), {
    view: 'without-border',
    animation: 'none',
    withoutHeader: true
  }),
  AppDashboardModal: modalWrap(() => import('./dashboard-add')),
  AppScalesEditorModal: modalWrap(() => import('./scales-editor'), {
    view: 'without-border'
  }),
  AppModbusGatewayEditorModal: modalWrap(() => import('./modbus-gateway-editor')),
  AppMqttGatewayEditorModal: modalWrap(() => import('./mqtt-gateway-editor')),
  AppMqttClientEditorModal: modalWrap(() => import('./mqtt-client-editor')),
  AppProjectEditorModal: modalWrap(() => import('./project-editor'), {
    view: 'without-border',
    animation: 'none',
    withoutHeader: true
  }),
  AppNotificationModal: modalWrap(() => import('./notification')),
  AppTagSelectionModal: modalWrap(() => import('./tag-selection'), {
    view: 'without-border',
    closeOnOverlay: false
  }),
  AppTagMoveModal: modalWrap(() => import('./tag-move'), {
    view: 'without-border'
  }),
  AppTagTemplateModal: modalWrap(() => import('./tag-template')),
  AppTagDeleteModal: modalWrap(() => import('./tag-delete')),
  AppChangePasswordModal: modalWrap(() => import('./change-password')),
  AppScriptEditorModal: modalWrap(() => import('./script-editor'), {
    view: 'without-border',
    withoutHeader: true,
    closeOnOverlay: false
  }),
  AppScriptImportModal: modalWrap(() => import('./script-import'), {
    view: 'without-border',
    closeOnOverlay: false
  }),
  AppScriptMoveModal: modalWrap(() => import('./script-move'), {
    view: 'without-border'
  }),
  AppScriptTriggerEditorModal: modalWrap(() => import('./script-trigger-editor'), {
    view: 'without-border'
  }),
  AppUserGroupModal: modalWrap(() => import('./user-group')),
  AppAddTelegramModal: modalWrap(() => import('./add-telegram')),
  AppAnalyticWidgetEditorModal: modalWrap(() => import('./analytic-widget-editor'), {
    view: 'without-border',
    withoutHeader: true
  })
}
