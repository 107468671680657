const LIMIT = 60

const TYPES = {
  FIELD: 'FIELD',
  TEXT: 'TEXT',
  DIVIDER: 'DIVIDER',
  GAUGE: 'GAUGE',
  RADIAL: 'RADIAL',
  PIE: 'PIE',
  SWITCH: 'SWITCH',
  TRENDS: 'TRENDS',
  SMALL_TABLE: 'SMALL_TABLE',
  INDICATOR: 'INDICATOR',
  BUTTON: 'BUTTON',
  MAP: 'MAP'
}

const COLORS = {
  GREY: 'GREY',
  BLACK: 'BLACK',
  RED: 'RED',
  BLUE: 'BLUE',
  YELLOW: 'YELLOW',
  ORANGE: 'ORANGE',
  GREEN: 'GREEN'
}

const CSS_COLORS = {
  FANDANGO: 'widgetExtraFandango',
  CELESTIAL_BLUE: 'widgetExtraCelestialBlue',
  SELECTIVE_YELLOW: 'widgetExtraSelectiveYellow',
  EMERALD: 'widgetExtraEmerald',
  IMPERIAL_RED: 'widgetExtraImperialRed',
  GREY: 'widgetExtraStateGrey',
  ROBIN_EGG_BLUE: 'widgetExtraRobinEggBlue',
  SAVOY_BLUE: 'widgetExtraSavoyBlue',
  TEAL: 'widgetExtraTeal',
  PLATINUM: 'widgetExtraPlatinum',
  NIGHT: 'widgetExtraNight',
  PRINECTON_ORANGE: 'widgetExtraPrinectonOrange'
}

const TYPE_TEXT_ALIGN = {
  LEFT: 'LEFT',
  CENTER: 'CENTER',
  RIGHT: 'RIGHT'
}
const TYPE_TEXT_COLOR_LIST = [
  COLORS.GREY,
  COLORS.BLACK,
  COLORS.RED,
  COLORS.BLUE,
  COLORS.ORANGE,
  COLORS.GREEN
]
const TYPE_TEXT_FONT_SIZE = {
  L: 'L',
  S: 'S'
}

const TYPE_INDICATOR_COLOR_LIST = [
  COLORS.RED,
  COLORS.GREEN,
  COLORS.YELLOW
]

const TYPE_DIVIDER_VIEW = {
  VERTICAL: 'VERTICAL',
  HORIZONTAL: 'HORIZONTAL'
}
const TYPE_DIVIDER_COLOR_LIST = [
  COLORS.GREY,
  COLORS.BLACK,
  COLORS.RED,
  COLORS.BLUE,
  COLORS.YELLOW,
  COLORS.ORANGE,
  COLORS.GREEN
]

const TYPE_RADIAL_VIEW = {
  HALF: 'HALF',
  SHOE: 'SHOE'
}

const TYPE_GAUGE_VIEW = {
  VERTICAL: 'VERTICAL',
  HORIZONTAL: 'HORIZONTAL'
}

const TYPE_GAUGE_ANIMATION = 3000
const TYPE_GAUGE_INIT_ANIMATION = 300

const TYPE_SMALL_TABLE_COL_LIMIT = 15
const TYPE_SMALL_TABLE_ROW_LIMIT = 30

const TYPE_TRENDS_LIMIT = 10
const TYPE_TRENDS_COLOR_LIST = [
  CSS_COLORS.FANDANGO,
  CSS_COLORS.CELESTIAL_BLUE,
  CSS_COLORS.SELECTIVE_YELLOW,
  CSS_COLORS.EMERALD,
  CSS_COLORS.IMPERIAL_RED,
  CSS_COLORS.GREY,
  CSS_COLORS.ROBIN_EGG_BLUE,
  CSS_COLORS.SAVOY_BLUE,
  CSS_COLORS.TEAL,
  CSS_COLORS.PLATINUM,
  CSS_COLORS.NIGHT,
  CSS_COLORS.PRINECTON_ORANGE
]

const TYPE_SANKEY_VALUE = {
  RELATIVE: 'RELATIVE',
  ABSOLUTE: 'ABSOLUTE'
}
const TYPE_SANKEY_COLOR_LIST = [
  CSS_COLORS.FANDANGO,
  CSS_COLORS.CELESTIAL_BLUE,
  CSS_COLORS.SELECTIVE_YELLOW,
  CSS_COLORS.EMERALD,
  CSS_COLORS.IMPERIAL_RED,
  CSS_COLORS.GREY,
  CSS_COLORS.ROBIN_EGG_BLUE,
  CSS_COLORS.SAVOY_BLUE,
  CSS_COLORS.TEAL,
  CSS_COLORS.PLATINUM,
  CSS_COLORS.NIGHT,
  CSS_COLORS.PRINECTON_ORANGE
]

const TYPE_BUTTON_COLOR_LIST = [
  COLORS.GREEN,
  COLORS.ORANGE,
  COLORS.RED,
  COLORS.BLUE,
  COLORS.GREY
]

const TYPE_MAP_PIN_LIMIT = 20
const TYPE_MAP_STATE = {
  ALARM: 'ALARM',
  WARNING: 'WARNING'
}

export default {
  LIMIT,
  TYPES,
  COLORS,
  CSS_COLORS,
  TYPE_TEXT_ALIGN,
  TYPE_TEXT_COLOR_LIST,
  TYPE_TEXT_FONT_SIZE,
  TYPE_INDICATOR_COLOR_LIST,
  TYPE_DIVIDER_VIEW,
  TYPE_DIVIDER_COLOR_LIST,
  TYPE_RADIAL_VIEW,
  TYPE_GAUGE_VIEW,
  TYPE_GAUGE_ANIMATION,
  TYPE_GAUGE_INIT_ANIMATION,
  TYPE_TRENDS_LIMIT,
  TYPE_TRENDS_COLOR_LIST,
  TYPE_SMALL_TABLE_COL_LIMIT,
  TYPE_SMALL_TABLE_ROW_LIMIT,
  TYPE_SANKEY_VALUE,
  TYPE_SANKEY_COLOR_LIST,
  TYPE_BUTTON_COLOR_LIST,
  TYPE_MAP_PIN_LIMIT,
  TYPE_MAP_STATE
}
