import http from '../instance'

const buildChart = ({ projectId, chart, dateFrom, dateTo }) =>
  http.post('/analytic-dashboards/charts/build', { projectId, chart, dateFrom, dateTo })

const getChartData = ({ projectId, sources, dateFrom, dateTo }) =>
  http.post('analytic-dashboards/charts/data', { projectId, sources, dateFrom, dateTo })

export default {
  buildChart,
  getChartData
}
